import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchCollections } from '../../utils/collectionUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { Loading } from '../components/ui/loading'
import { SelectDefault } from '../components/ui/select'
import ConditionsRewards from './_components/ConditionsRewards'
import { SpecificRewards } from './_components/SpecificRewards'
import SwitchSpecificReward from './_components/SwitchSpecificReward'
import TokenOption from './_components/TokenOption'

export default function CreateStakingSetup() {
  const guildId = localStorage.getItem('guildId')
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [isSpecificReward, setIsSpecificReward] = useState(false)
  const [rewardsPerDays, setRewardsPerDays] = useState(0)
  const [minimumWithdrawBalance, setMinimumWithdrawBalance] = useState(0)
  const [minimumWithdrawDays, setMinimumWithdrawDays] = useState(0)
  const [stakedCollections, setStakedCollections] = useState([])
  const [stakedTokens, setStakedTokens] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleRewardsPerDays = (value) => {
    setRewardsPerDays(value)
  }
  const handleMinimumWithdrawBalance = (value) => {
    setMinimumWithdrawBalance(value)
  }
  const handleMinimumWithdrawDays = (value) => {
    setMinimumWithdrawDays(value)
  }

  const handleStakedTokensChange = (value) => {
    setStakedTokens(value)
  }

  // load roles
  const [roles, setRoles] = useState([])
  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  // load channels
  const [channels, setChannels] = useState([])
  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //load collections
  const [collections, setCollections] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)

  useEffect(() => {
    if (!collections || collections.length === 0) {
      fetchCollections(guildId).then((data) => {
        setCollections(data)
      })
    }
  }, [])

  const collectionOptions = collections?.flatMap(
    (collection) =>
      collection.CollectionNotifications?.filter(
        (notification) => notification.IsEnabled && !notification.IsToken
      ).map((notification) => ({
        value: [
          {
            CollectionAddress: notification.CollectionMint,
            VerifiedAddress: notification.VerifiedAddress,
          },
        ],
        label: notification.DisplayName,
      })) || []
  )

  const handleRoleChange = (value) => {
    setSelectedRole(value)
  }

  const handleChannelChange = (value) => {
    setSelectedChannel(value)
  }

  const handleCollectionChange = (value) => {
    setSelectedCollection(value)
  }

  const handleCreateStakingSetup = async () => {
    setLoading(true)
    const errors = []
    if (!selectedRole) {
      errors.push('Please select a role.')
    }
    if (!selectedChannel) {
      errors.push('Please select a channel.')
    }
    if (!selectedCollection) {
      errors.push('Please select a collection.')
    }
    if (!stakedTokens) {
      errors.push('Please select a staked token.')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      setLoading(false)
      return
    }
    const infoCreateStaking = {
      guildId: localStorage.getItem('guildId'),
      eligibleRole: [selectedRole.value],
      stakedTokens: [
        {
          tokenName: stakedTokens.label,
          tokenAddress: stakedTokens.value,
          tokenImage: stakedTokens.image,
        },
      ],
      stakingChannelId: selectedChannel.value,
      rewardDetails: {
        dailyRewardAmount: rewardsPerDays,
        minimumWithdrawalBalance: minimumWithdrawBalance,
        minimumWithdrawalDays: minimumWithdrawDays,
      },
      stakedCollections,
    }
    HttpService.postCreateStaking(infoCreateStaking)
      .then((response) => {
        toast.success('Staking setup created successfully!')
        setLoading(false)
      })
      .catch((error) => {
        toast.error(`Opss, something gone wrong: ${error.message}`)
        setLoading(false)
      })
  }

  return (
    <>
      {loading === true && <Loading />}
      <section className="flex flex-col relative">
        <div className="container mx-auto flex flex-col gap-y-5 py-5 h-full ">
          {/* section 1 */}
          <div className="flex gap-x-5">
            {/* Select Role */}
            <SelectDefault
              label="Select a Role"
              placeholder="Select Role"
              options={dropdownRoles}
              onChange={handleRoleChange}
            />

            {/* Select Channel */}
            <SelectDefault
              label="Select a Channel"
              placeholder="Select Channel"
              options={dropdownChannels}
              onChange={handleChannelChange}
            />
          </div>
          {/* Select Collection */}
          <SelectDefault
            label="Select a Collection"
            placeholder="Select Collection"
            isMulti={true}
            options={collectionOptions}
            onChange={handleCollectionChange}
          />
          <hr className="w-full border-third my-10" />
          {/* section 2 */}
          <div className="flex flex-col gap-y-8">
            <TokenOption onStakedTokensChange={handleStakedTokensChange} />
            <ConditionsRewards
              onRewardsPerDaysChange={handleRewardsPerDays}
              onMinimumWithdrawBalanceChange={handleMinimumWithdrawBalance}
              onMinimumWithdrawDaysChange={handleMinimumWithdrawDays}
            />
            <hr className="w-full border-third my-10" />
          </div>
          {/* section 3 */}
          {selectedCollection?.length > 0 && (
            <div className="flex flex-col gap-3 w-full">
              {/* Switch Specific Reward */}
              <SwitchSpecificReward
                isChecked={isSpecificReward}
                setIsChecked={() => setIsSpecificReward(!isSpecificReward)}
              />
              <div
                className={`w-full h-full transition-all duration-300 my-10 ${isSpecificReward ? 'flex' : 'hidden'}`}
              >
                <SpecificRewards
                  collectionsRewards={selectedCollection}
                  setStakedCollections={setStakedCollections}
                />
              </div>
            </div>
          )}
          {/* section 4 - button */}
          <div className="flex justify-end my-5">
            <Button
              color="deep-purple"
              className="bg-main"
              size="lg"
              onClick={handleCreateStakingSetup}
            >
              Create
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}
