import { Button, Card, Input } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BiSearch } from 'react-icons/bi'
import { CgTrash } from 'react-icons/cg'
import { toast } from 'react-toastify'
import { fetchTraits } from '../../../utils/traitsUtils'
import { InputNumber } from '../../components/ui/input'
import { SelectDefault } from '../../components/ui/select'

const TraitRewards = ({
  rewards,
  onCollectionsUpdate,
  onCollectionAddress,
  onCollections,
  onUpdateTraits,
}) => {
  const guildId = localStorage.getItem('guildId')
  const [collectionMint, setCollectionMint] = useState(
    onCollections.CollectionAddress
  )
  const [creatorAddress, setCreatorAddress] = useState(
    onCollections.VerifiedAddress
  )
  const [traits, setTraits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [collection, setCollection] = useState([])
  const [selectedTraitType, setSelectedTraitType] = useState(null)
  const [selectedTraitValue, setSelectedTraitValue] = useState(null)
  const [valueTotalReward, setValueTotalReward] = useState(1)

  //load collections
  const [selectedCollection, setSelectedCollection] = useState(
    onCollectionsUpdate || onCollections
  )

  // load traits
  const getTraits = async () => {
    setIsLoading(true)
    fetchTraits(collectionMint, creatorAddress, guildId).then((data) => {
      setTraits(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getTraits()
  }, [collectionMint, creatorAddress, guildId])

  const handleValueTotalReward = (value) => {
    setValueTotalReward(value)
  }

  const traitOptions = traits?.map((trait) => ({
    value: trait.TraitType,
    label: trait.TraitType,
  }))

  const traitValueOptions = selectedTraitType
    ? traits
        ?.find((trait) => trait.TraitType === selectedTraitType.value)
        ?.TraitValue?.map((value) => ({
          value,
          label: value,
        })) || []
    : []

  const handleAddTrait = () => {
    if (!selectedTraitType || !selectedTraitValue) return
    const newTrait = {
      type: selectedTraitType.value,
      value: selectedTraitValue.value,
      reward: valueTotalReward,
    }
    // Verify if the item already exists in the collection
    const exists = collection.some(
      (trait) => trait.type === newTrait.type && trait.value === newTrait.value
    )
    if (exists) {
      toast.error('Item already exists in collection!')
    } else {
      const updatedCollection = [newTrait, ...collection]
      setCollection(updatedCollection)
      onUpdateTraits(updatedCollection)
    }
    // Reset the values
    setSelectedTraitType(null)
    setSelectedTraitValue(null)
    setValueTotalReward(1)
  }

  const handleDeleteTrait = (index) => {
    const updatedCollection = collection.filter((_, i) => i !== index)
    setCollection(updatedCollection)
  }

  // filter collection
  const filteredCollection = collection?.filter(
    (trait) =>
      trait.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      trait.value.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <>
      <div className="flex flex-col gap-y-2 min-h-96">
        {/* ADD Traits Rewards */}
        {selectedCollection && (
          <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-x-2 z-50">
            {/* TRAITS */}
            <div className="w-full flex gap-x-2">
              {/* NFT TYPE */}
              <SelectDefault
                label="Type"
                options={traitOptions}
                onChange={(selectedOption) => {
                  setSelectedTraitType(selectedOption)
                }}
                value={selectedTraitType}
                isLoading={isLoading}
              />
              {/* NFT VALUE */}
              <SelectDefault
                label="Value"
                options={traitValueOptions}
                onChange={(selectedOption) => {
                  setSelectedTraitValue(selectedOption)
                }}
                value={selectedTraitValue}
                isLoading={isLoading}
              />
            </div>

            {/* QUANTITY REWARD */}
            <InputNumber
              label="Reward"
              value={valueTotalReward}
              onChange={handleValueTotalReward}
            />
            {/* ADD BUTTON */}
            <div className="flex items-end justify-center shadow-lg">
              <Button
                color="deep-purple"
                className="bg-main hover:bg-mainHover w-12 h-full max-h-[48px] flex items-center justify-center"
                onClick={handleAddTrait}
              >
                ADD
              </Button>
            </div>
          </div>
        )}
        {/* Table Traits Rewards */}
        <Card className=" bg-basic min-h-28 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
          <Input
            placeholder="Filter by type or value"
            variant="static"
            color="deep-purple"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
            className="w-full bg-second border-none px-5 text-white"
            icon={<BiSearch className="text-xl text-main -ml-10" />}
          />
          <table className="w-full text-left">
            <thead className="text-xs bg-second uppercase">
              <tr>
                <th scope="col" className="hidden text-center py-2">
                  ID
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Type
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Value
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Reward
                </th>
                <th scope="col" className="text-start py-2 pl-5"></th>
              </tr>
            </thead>
            {filteredCollection?.map((trait, index) => (
              <tbody
                key={index}
                className={!filteredCollection ? 'hidden' : ''}
              >
                <tr className="bg-basic hover:bg-third border-second border-t font-light">
                  <td className="px-5 py-2 border-r border-second">
                    {trait.type}
                  </td>
                  <td className="px-5 py-2 border-r border-second ">
                    {trait.value}
                  </td>
                  <td className="px-5 py-2 border-r border-second">
                    {trait.reward}
                  </td>
                  <td className="w-10 h-8 hover:bg-accentHover">
                    <Button
                      color="deep-purple"
                      className="bg-main hover:bg-mainHover flex items-center justify-center text-white h-full w-10 p-px rounded-none rounded-l-lg"
                      onClick={() => handleDeleteTrait(index)}
                    >
                      <CgTrash size={20} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          {!filteredCollection.length && (
            <div className="text-center italic text-xs p-2">
              no specific reward has been configured
            </div>
          )}
        </Card>
      </div>
    </>
  )
}

export default TraitRewards
