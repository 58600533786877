export const rolesDataSimulated = [
  {
    id: 1,
    name: '😃 Teste roles 1',
    role: 'Tektools',
  },
  {
    id: 2,
    name: '👍 Teste roles 2',
    role: 'Tektools',
  },
  {
    id: 3,
    name: '🤣 Teste roles 3',
    role: 'Tektools',
  },
]

export const reactionRolesDataSimulated = [
  {
    id: '1314343235212283967',
    emoji: '👍',
    role: 'Tektools',
    text: 'O talento vence jogos, mas só o trabalho em equipe ganha campeonatos.',
  },
  {
    id: '131431231451283967',
    emoji: '🎮',
    role: 'Admin',
    text: 'Eu quero jogar um jogo',
  },
]
