import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import CreateStakingSetup from './CreateStakingSetup'
import LoadStakingSetup from './LoadStakingSetup'

const Staking = () => {
  const [openNewStaking, setOpenNewStaking] = useState(false)

  return (
    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="flex justify-between items-center container mx-auto pb-10">
        <h1 className="text-4xl font-bold">Staking Setup</h1>
        <Button
          color="purple"
          className="flex max-w-40 min-w-14 h-12 font-bold px-4 py-3 items-center justify-center "
          onClick={() => setOpenNewStaking((cur) => !cur)}
        >
          {!openNewStaking ? 'New Staking' : <BiLeftArrowAlt size={20} />}
        </Button>
      </div>
      {openNewStaking ? (
        <CreateStakingSetup />
      ) : (
        <div className="flex flex-wrap w-full items-center justify-center gap-y-5">
          <LoadStakingSetup />
        </div>
      )}
    </section>
  )
}

export default Staking
