import { HttpService } from './axios-httpService'

export const fetchTraits = async (collectionMint, creatorAddress, guildId) => {
  const payload = {
    guildId,
    collectionMint,
    creatorAddress,
  }
  try {
    const response = await HttpService.postTraits(payload)
    return response.data
  } catch (error) {
    throw new Error('Error fetching traits')
  }
}

// TODO: remove this function
export const getTraits = async (collectionAddress, creatorAddress) => {
  if (!collectionAddress && !creatorAddress) {
    return null
  }
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/collections/traits`
    const body = {
      guildId: localStorage.getItem('guildId'),
      collectionMint: collectionAddress || '',
      creatorAddress: creatorAddress || '',
    }
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.statusText}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erro ao buscar traits:', error)
    return null
  }
}
