import { Card, Spinner } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import { BiEdit } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { getStaking } from '../../utils/stakingUtils'

const LoadStakingSetup = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)
  const [stakingList, setStakingList] = useState([])

  useEffect(() => {
    if (!stakingList || stakingList.length === 0) {
      setIsLoading(true)
      getStaking(guildId).then((data) => {
        setStakingList(data)
        setIsLoading(false)
      })
    }
  }, [])

  return (
    <>
      <div className="flex flex-wrap gap-5 overflow-y-hidden scrollbar-thin scrollbar-thumb-second scrollbar-track-basic pb-10 items-center justify-center">
        {/* Card loaded with staking list */}
        {stakingList?.map((staking, index) => (
          <Card
            key={index}
            className="h-[350px] w-[350px] group cursor-pointer overflow-hidden transition-opacity hover:opacity-90 shadow-md bg-basic"
          >
            {stakingList?.length === 0 && (
              <div className="w-full h-full flex items-center justify-center">
                <Spinner className="w-12 h-12 text-main/10" />
              </div>
            )}
            <div className="w-full h-full flex flex-row bg-basic rounded-lg">
              {/* Token conditions */}
              <div className="w-[75%] h-full flex flex-col bg-second rounded-lg shadow gap-x-2">
                {/* Server name */}
                <div className="w-full flex flex-row items-end gap-x-5 pt-2 pl-5">
                  {/* Token name */}
                  <div className="w-full flex flex-row justify-end items-end gap-x-2 border-b-2 border-basic transition-all p-2">
                    <img
                      src={staking.StakedTokens[0].TokenImage}
                      alt={staking.StakedTokens[0].TokenName}
                      className="w-10 h-10 animate-spinslow rounded-full "
                    />
                    <span className="text-lg font-bold w-full truncate">
                      {staking.StakedTokens[0].TokenName}
                    </span>
                  </div>
                </div>
                <div className="w-full h-full flex flex-col items-end justify-start gap-y-5">
                  <div className="w-20 h-2 flex text-center items-center justify-center bg-basic rounded-bl-lg -mr-1 group-hover:w-44 transition-all duration-500"></div>
                  <div className="w-full h-full flex flex-col pl-5">
                    <span className="text-xs font-bold">Rewards:</span>
                    <div className="flex items-end">
                      <CountUp
                        end={staking.TotalRewardPerDay}
                        duration={0.7}
                        className="text-2xl font-bold"
                      />
                      <span className="text-xs font-bold pb-1">
                        coins / day
                      </span>
                    </div>

                    <div className=" flex-col w-full gap-y-5 flex pt-5">
                      {/* Specific rewards staked from the collection */}
                      {staking.TraitBased === false ? (
                        <span className="text-xs font-bold">
                          No custom rewards staked
                        </span>
                      ) : (
                        <div className="flex flex-col text-xs font-bold gap-y-2">
                          <span>Custom rewards for collection:</span>
                          {staking?.StakedCollections?.map(
                            (collection, index) => (
                              <div className="flex flex-wrap gap-2" key={index}>
                                <span className="flex p-1.5 bg-accentHouver rounded-lg w-fit">
                                  {collection.CollectionName}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Edit button */}
                  <div className="flex flex-row w-full gap-x-2 items-start pl-5 pb-5">
                    <NavLink
                      className="flex flex-row items-center justify-center text-center gap-x-2 w-24 h-10 text-base font-bold px-2  bg-main transition-all duration-300  rounded-lg shadow-md hover:shadow-main hover:shadow-sm"
                      to={`/dashboard/stakingsetup/${staking?._id}`}
                    >
                      <BiEdit className="text-lg" />
                      Edit
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* Token Rewards */}
              <div
                key={index}
                className="w-[25%] h-full flex flex-col items-center justify-center gap-y-2"
              >
                {/* Minimum coins withdrawal */}
                <div className="w-full flex flex-col">
                  <div className="flex flex-col items-center justify-center">
                    <span className="font-bold text-xs flex">Minimum</span>
                    <span className="font-bold text-xs flex -pt-1">
                      withdrawal
                    </span>
                  </div>
                  <div className="flex flex-row items-end justify-center">
                    <span className="text-lg font-bold">
                      <CountUp
                        end={staking.WithdrawMinimumBalance}
                        duration={0.7}
                      />
                    </span>
                    <span className="text-[10px] font-bold pb-1">Tokens</span>
                  </div>
                </div>
                <hr className="w-full border-second mr-10" />
                {/* Minimum days withdrawal */}
                <div className="w-full flex flex-col">
                  <div className="flex flex-col items-center justify-center">
                    <span className="font-bold text-xs flex">Withdrawal </span>
                    <span className="font-bold text-xs flex -pt-1">after</span>
                  </div>
                  <div className="flex flex-row items-end justify-center">
                    <span className="text-2xl font-bold">
                      <CountUp
                        end={staking.WithdrawMinimumDays}
                        duration={0.9}
                      />
                    </span>
                    <span className="text-[10px] font-bold pb-1">Days</span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </>
  )
}

export default LoadStakingSetup
