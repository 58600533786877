import { Button, Input } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'

export const InputDefault = ({
  value,
  onChange,
  label,
  placeholder,
  readOnly,
}) => {
  return (
    <>
      <div className="w-full flex flex-col justify-end shadow-lg">
        <span className="text-base font-semibold">{label}</span>
        <div className="w-full flex flex-col bg-second rounded-lg px-1 border border-third">
          <Input
            placeholder={placeholder}
            color="deep-purple"
            variant="static"
            className=" w-full text-white font-bold text-lg first-of-type:pl-4 border-none "
            size="lg"
            containerProps={{
              className: 'bg-second ',
            }}
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
              style: {
                color: 'white',
              },
            }}
            readOnly={readOnly}
            value={value}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  )
}

export const InputButton = ({
  value,
  onChange,
  label,
  placeholder,
  readOnly,
  children,
  colorButton,
}) => {
  return (
    <>
      <div className="w-full flex flex-col justify-end shadow-lg">
        <span className="text-base font-semibold">{label}</span>
        <div className="relative w-full flex flex-col bg-second rounded-lg px-1 border border-third ">
          <Input
            placeholder={placeholder}
            color="deep-purple"
            variant="static"
            className="w-full text-white font-bold first-of-type:pl-4 border-none pr-20 itens-center"
            size="lg"
            containerProps={{
              className: 'bg-second ',
            }}
            labelProps={{
              className:
                'peer-focus:text-white peer-focus:font-bold peer-focus:text-lg border-none outline-none placeholder-shown:text-white  font-bold text-lg tracking-widest   peer-focus:outline-none peer-focus:border-none border-none  peer-focus:text-[#663399]',
              style: {
                color: 'white',
              },
            }}
            readOnly={readOnly}
            value={value}
            onChange={onChange}
          />
          {children}
        </div>
      </div>
    </>
  )
}

export const InputNumber = ({
  value,
  onChange,
  label,
  setMinimum,
  maxSize,
}) => {
  const minimum = setMinimum === undefined ? 0 : setMinimum
  const [valueQuantity, setValueQuantity] = useState(minimum)
  const incrementQuantity = () => {
    setValueQuantity((prev) => prev + 1)
  }

  const decrementQuantity = () => {
    setValueQuantity((prev) => (prev > 1 ? prev - 1 : minimum))
  }

  const handleQuantityChange = (e) => {
    const value = Number.parseInt(e.target.value)
    setValueQuantity(value > minimum ? value : 1)
  }

  useEffect(() => {
    if (onChange) {
      onChange(valueQuantity)
    }
  }, [valueQuantity, onChange])

  return (
    <>
      <div
        className={`flex flex-col justify-end h-full shadow-lg ${maxSize === true ? 'w-full' : ''}`}
      >
        <span className="text-base font-semibold">
          {label ? label : <>&nbsp;</>}
        </span>
        <div className="flex items-center h-full transition duration-300 rounded-lg shadow pt-px bg-second border border-third">
          <input
            type="number"
            min={1}
            value={value ? value : valueQuantity}
            onChange={handleQuantityChange}
            className={` h-full text-white  first-of-type:pl-4 border-none bg-transparent min-w-16 w-full outline-none ${maxSize === true ? 'w-full' : 'w-32'}`}
          />
          <div className="flex flex-col h-full">
            <button
              onClick={incrementQuantity}
              className="bg-main hover:bg-mainHover p-1 w-6 flex items-center justify-center rounded-t-md"
            >
              <BiPlus />
            </button>
            <hr className="border-second" />
            <button
              onClick={decrementQuantity}
              className="bg-main hover:bg-mainHover p-1 w-6  flex items-center justify-center rounded-b-md"
            >
              <BiMinus />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
