import { Button, IconButton } from '@material-tailwind/react'
import React, { useState } from 'react'
import { BiTrash } from 'react-icons/bi'
import { BiEdit } from 'react-icons/bi'
import { BsTelegram } from 'react-icons/bs'
import { BsDiscord } from 'react-icons/bs'
import { BsTwitterX } from 'react-icons/bs'

const userData = JSON.parse(localStorage.getItem('userData'))

const listSocial = [
  {
    icon: BsDiscord,
    social: 'Discord',
    name: userData?.user?.global_name,
    link: `https://discord.com/users/${userData?.user?.id}`,
  },
  {
    icon: BsTwitterX,
    social: 'X',
    name: 'userName X',
    link: 'https://twitter.com/1234567890',
  },
  {
    icon: BsTelegram,
    social: 'Telegram',
    name: 'Telegram',
    link: 'https://t.me/1234567890',
  },
]

const Social = () => {
  const [socialAccount, setSocialAccount] = useState(listSocial || [])

  const deleteSocial = (index) => {
    const newSocial = socialAccount.map((item, i) => {
      if (i === index) {
        return { ...item, name: '' } // Mantém todos os dados, mas limpa apenas o nome
      }
      return item
    })
    setSocialAccount(newSocial)
  }

  return (
    <div className="w-full flex items-start">
      <div className="flex w-full justify-between">
        {socialAccount.map((social, index) => (
          <div key={index} className="flex gap-x-1">
            <div className="flex gap-x-5 items-center ">
              <social.icon size={22} className="text-main" />
              <span className="font-medium text-sm italic pr-2 min-w-20 truncate text-ellipsis ">
                {social.name}
              </span>
            </div>
            <IconButton className="bg-mainHover/30">
              <BiEdit className="text-main h-5 w-5" />
            </IconButton>
            <IconButton className="bg-mainHover/30">
              <BiTrash
                onClick={() => deleteSocial(index)}
                className="text-main h-5 w-5"
              />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Social
