import React from 'react'
import Header from '../../components/Header/Header'
import SetupAccount from './_components/SetupAccount'
const Account = () => {
  return (
    <div className="text-white w-full h-screen flex flex-col bg-bgAdmin">
      <div className="w-full ">
        <Header />
      </div>
      <div className="w-full h-full">
        <SetupAccount />
      </div>
    </div>
  )
}

export default Account
