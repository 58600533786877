import { HttpService } from './axios-httpService'

// TODO: Remove this function
export const getCollection = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/collections?guildId=${localStorage.getItem('guildId')}`
  )
  const data = await response.json()
  if (!response.ok) {
    throw new Error('Error in API response')
  }
  return data
}

export const getCollectionById = async (id, guildId) => {
  try {
    const response = await HttpService.getCollectionById(id, guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching collections')
  }
}

export const fetchCollections = async (guildId) => {
  try {
    const response = await HttpService.getCollections(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching collections')
  }
}
