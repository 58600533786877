import { HttpService } from './axios-httpService'

export const fetchGuilds = async () => {
  try {
    const response = await HttpService.getGuilds()
    return response.data
  } catch (error) {
    throw new Error('Error fetching guilds')
  }
}

// TODO: Remove this function
export const getGuilds = async () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const accessToken = userData.tokenInfo.accessToken
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/guilds`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          accessToken: accessToken,
        },
      }
    )

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching server list:', error)
    return null
  }
}
