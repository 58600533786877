import { Input, Option, Select, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { BsFillCloudArrowUpFill } from 'react-icons/bs'
import { fetchRoles } from '../../../utils/rolesUtils'
import { InputDefault } from '../../components/ui/input'
import { SelectDefault } from '../../components/ui/select'

const NftSetup = ({
  onRoleSelect,
  onQuantitySelect,
  onCreatorAddress,
  onCollectionName,
  onCollectionAddress,
  onHashlist,
  onHashlistFile,
  onSkipRole,
}) => {
  const guildId = localStorage.getItem('guildId')
  const [value, setValue] = useState(1)
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [creatorAddress, setCreatorAddress] = useState('')
  const [collectionName, setCollectionName] = useState('')
  const [collectionAddress, setCollectionAddress] = useState('')
  const [isCollectionAddress, setIsCollectionAddress] = useState(false)
  const [isHashlist, setIsHashlist] = useState(false)
  const [isSkipRole, setIsSkipRole] = useState(false)
  const [hashlistFile, setHashlistFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // load roles
  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const roleOptions = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  useEffect(() => {
    onQuantitySelect(value)
  }, [value, onQuantitySelect])

  useEffect(() => {
    onRoleSelect(selectedRole)
  }, [selectedRole, onRoleSelect])

  useEffect(() => {
    onCollectionName(collectionName)
  }, [collectionName, onCollectionName])

  useEffect(() => {
    onCreatorAddress(creatorAddress)
  }, [creatorAddress, onCreatorAddress])

  useEffect(() => {
    onCollectionAddress(collectionAddress)
  }, [collectionAddress, onCollectionAddress])

  useEffect(() => {
    onHashlist(isHashlist)
  }, [isHashlist, onHashlist])

  useEffect(() => {
    onSkipRole(isSkipRole)
  }, [isSkipRole, onSkipRole])

  useEffect(() => {
    onHashlistFile(hashlistFile)
  }, [hashlistFile, onHashlistFile])

  const handleHashlistUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setHashlistFile(file)
    }
  }

  return (
    <>
      <div className="flex flex-col gap-y-5 h-full w-full max-w-[1300px]">
        {/* section 1 */}
        <div className="flex flex-col gap-y-5 w-full">
          {/* Collection Name */}
          <InputDefault
            label="Enter a collection name*"
            placeholder="Example: My Collection"
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
          />
          <div className="flex flex-col w-full ">
            {/* Input Address */}
            {!isCollectionAddress ? (
              <InputDefault
                label={
                  <>
                    Enter a{' '}
                    {
                      <span className="text-base font-bold italic">
                        collection address*
                      </span>
                    }
                  </>
                }
                placeholder="Example: 0x1234567890123456789012345678901234567890"
                value={collectionAddress}
                onChange={(e) => setCollectionAddress(e.target.value)}
              />
            ) : (
              <InputDefault
                label={
                  <>
                    Enter a{' '}
                    {
                      <span className="text-base font-bold italic">
                        creator's address*
                      </span>
                    }
                  </>
                }
                placeholder="Example: 0x1234567890123456789012345678901234567890"
                value={creatorAddress}
                onChange={(e) => setCreatorAddress(e.target.value)}
              />
            )}
            <div className="flex justify-between pt-10">
              <span className="font-semibold">
                Don't have a collection address?
              </span>
              <Switch
                className={`${isCollectionAddress ? 'bg-mainHover' : ''}`}
                label={`${isCollectionAddress ? 'Yes' : 'No'}`}
                checked={isCollectionAddress}
                onChange={() => setIsCollectionAddress(!isCollectionAddress)}
              />
            </div>
          </div>
        </div>
        {/* section 2 */}
        <hr className="my-3 border-third" />
        <div className="flex flex-col gap-y-5">
          {/* Hashlist */}
          <div className="flex flex-col gap-y-2">
            <div className="flex w-full justify-between">
              <span className="font-semibold">
                Do you want to upload a hashlist?
              </span>
              <Switch
                className={`${isHashlist ? 'bg-mainHover' : ''}`}
                label={`${isHashlist ? 'Yes' : 'No'}`}
                checked={isHashlist}
                onChange={() => setIsHashlist(!isHashlist)}
              />
            </div>
            {isHashlist && (
              <div className="w-full flex justify-end">
                <div
                  div
                  className={`flex items-end justify-end border border-main p-2 rounded-lg w-52 group ${hashlistFile ? 'hover:bg-main hover:border hover:border-third' : ''}`}
                >
                  <label
                    htmlFor="hashlist-upload"
                    className={`items-center justify-center cursor-pointer w-full flex gap-x-2 `}
                  >
                    {hashlistFile ? (
                      <div className="flex items-center justify-center text-sm font-bold w-full">
                        <span
                          className={
                            hashlistFile ? 'group-hover:hidden' : 'hidden'
                          }
                        >
                          {hashlistFile ? hashlistFile.name : 'Upload List'}
                        </span>
                        <span
                          className={
                            hashlistFile
                              ? 'hidden group-hover:flex items-center justify-center text-white uppercase'
                              : 'group-hover:hidden'
                          }
                        >
                          change file
                        </span>
                      </div>
                    ) : (
                      <div className="text-white text-sm font-bold uppercase flex flex-row gap-x-2 items-center">
                        Upload List <BsFillCloudArrowUpFill size={24} />
                      </div>
                    )}
                  </label>
                  <input
                    id="hashlist-upload"
                    type="file"
                    accept=".csv, .xml, .json"
                    className="hidden"
                    onChange={handleHashlistUpload}
                  />
                </div>
              </div>
            )}
          </div>
          {/* Skip Role */}
          <div className="flex w-full justify-between items-center">
            <span className=" font-semibold">Do you want to skip role?</span>
            <Switch
              className={`${isSkipRole ? 'bg-mainHover' : ''}`}
              label={`${isSkipRole ? 'Yes' : 'No'}`}
              checked={isSkipRole}
              onChange={() => setIsSkipRole(!isSkipRole)}
            />
          </div>
        </div>
        {/* section 3 */}
        <hr className="my-3 border-third" />
        <div className="flex gap-x-5 justify-end">
          {/* Select Role */}
          <SelectDefault
            label="Select a Role"
            value={selectedRole}
            options={roleOptions}
            isLoading={isLoading}
            onChange={(selectedOption) => {
              setSelectedRole(selectedOption)
            }}
          />
        </div>
      </div>
    </>
  )
}

export default NftSetup
