import { Button } from '@material-tailwind/react'
import EmojiPicker from 'emoji-picker-react'
import React, { useState, useEffect } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiTrash } from 'react-icons/bi'
import { MdOutlineEmojiEmotions } from 'react-icons/md'
import { toast } from 'react-toastify'
import { fetchRoles } from '../../utils/rolesUtils'
import { InputDefault } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'
import { reactionRolesDataSimulated } from './dataSimulated'
const ReactionRoles = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)
  const [emoji, setEmoji] = useState(null)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [messageId, setMessageId] = useState(null)
  const [reactionRolesDefined, setReactionRolesDefined] = useState(
    reactionRolesDataSimulated ? reactionRolesDataSimulated : []
  )
  //Emoji
  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject.emoji)
    setShowEmojiPicker(false)
  }

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const addReactionRole = () => {
    const errors = []
    if (!messageId) errors.push('Please enter a message id.')
    if (!selectedRole) errors.push('Please select a role.')
    if (!emoji) errors.push('Please enter an emoji.')
    if (errors.length > 0) {
      toast.error(errors.join('\n'), {
        style: { whiteSpace: 'pre-line' },
      })
    } else {
      setReactionRolesDefined([
        ...reactionRolesDefined,
        { id: messageId, emoji: emoji, role: selectedRole.label },
      ])
      toast.success('Reaction role added successfully.')
      setMessageId('')
      setSelectedRole(null)
      setEmoji('')
    }
  }

  const handleDeleteReactionRole = (id) => {
    setReactionRolesDefined(
      reactionRolesDefined.filter((reactionRole) => reactionRole.id !== id)
    )
  }

  return (
    <>
      <div className="w-full flex flex-col gap-5 z-10">
        <div className="flex gap-3 items-end">
          {/* Message Id */}
          <InputDefault
            label="The id of the message to listen to"
            placeholder="Enter message id. Ex: 1313571497717268593"
            value={messageId}
            onChange={(e) => setMessageId(e.target.value)}
          />
          {/* Emoji Reaction */}
          <div className="w-fit flex flex-col justify-end items-center shadow-lg relative">
            <span className="text-base font-semibold">Enter emoji</span>
            <div className="w-full flex bg-second rounded-lg px-1 border border-third items-center h-[52px]">
              <div className="flex w-full items-center ">
                <input
                  className="w-full  text-white  first-of-type:pl-4 outline-none bg-second border-none "
                  value={emoji}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace') {
                      setEmoji(null)
                    }
                  }}
                />
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="p-2 relative"
                >
                  <MdOutlineEmojiEmotions size={20} />
                </button>
              </div>
              {showEmojiPicker && (
                <div className="absolute top-full mt-2 right-0">
                  <EmojiPicker
                    theme="dark"
                    searchDisabled
                    skinTonesDisabled
                    recentEmojis={false}
                    onEmojiClick={onEmojiClick}
                  />
                </div>
              )}
            </div>
          </div>
          {/* Role to give */}
          <SelectDefault
            isLoading={isLoading}
            isDisabled={isLoading}
            label="The role to give"
            value={selectedRole}
            onChange={(value) => setSelectedRole(value)}
            options={dropdownRoles}
          />
          <div className="flex gap-3 items-end">
            <Button
              variant="filled"
              color="purple"
              className="!w-fit whitespace-nowrap bg-main h-[52px] "
              onClick={addReactionRole}
            >
              Add Reaction Role
            </Button>
          </div>
        </div>
        <div className="w-full flex flex-wrap gap-5 h-auto">
          {reactionRolesDefined.map((reactionRole) => (
            <div
              key={reactionRole.id}
              className="flex rounded-lg bg-second  shadow-xl"
            >
              <div className="flex flex-col h-full rounded-l-lg w-full px-2 py-1">
                <div className="flex justify-between items-center gap-2 relative group">
                  <span className="flex text-sm font-semibold">
                    {reactionRole.id}
                  </span>
                  <div className="font-black flex items-center justify-center rounded-r-lg">
                    <AiOutlineInfoCircle />
                  </div>
                  {reactionRole.text && (
                    <div className="absolute -top-5 -right-0 translate-x-full hidden group-hover:flex w-full bg-third shadow-lg rounded-lg p-2 z-40 transition-all duration-300">
                      {reactionRole.text}
                    </div>
                  )}
                </div>
                <div className="w-full flex justify-between items-end">
                  <span className="flex rounded-full p-px text-xl font-black bg-third/50 items-center justify-center">
                    {reactionRole.emoji}
                  </span>
                  <span className="flex  bg-second rounded-lg py-1 px-2 text-xs font-black text-main">
                    @{reactionRole.role}
                  </span>
                </div>
              </div>
              <div className="flex h-full">
                <Button
                  variant="text"
                  className="font-black flex items-center justify-center bg-main rounded-r-lg w-10 p-0"
                  onClick={() => handleDeleteReactionRole(reactionRole.id)}
                >
                  <BiTrash size={20} accentHeight={10} className=" " />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default ReactionRoles
