import { Button, Checkbox, IconButton } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { RxDividerHorizontal } from 'react-icons/rx'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import 'react-multi-date-picker/styles/colors/purple.css'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { InputNumber } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'

const SetupGiveaway = () => {
  const guildId = localStorage.getItem('guildId')
  const userId = JSON.parse(localStorage.getItem('userData')).user.id
  const [isLoading, setIsLoading] = useState(false)
  const [rewardDescription, setRewardDescription] = useState('')
  const [numberOfWinners, setNumberOfWinners] = useState(1)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const handleNumberOfWinners = (e) => {
    setNumberOfWinners(e)
  }

  const [selectedDuration, setSelectedDuration] = useState(null)

  const optionsDuration = [
    { label: '30min', value: '30min' },
    { label: '1h', value: '1h' },
    { label: '6h', value: '6h' },
    { label: '12h', value: '12h' },
    { label: '1d', value: '1d' },
    { label: '7d', value: '7d' },
    { label: '30d', value: '30d' },
    { label: 'Custom', value: 'Custom' },
  ]

  const calculateEndDate = (duration) => {
    const now = new Date()
    const end = new Date()

    switch (duration) {
      case '30min':
        end.setMinutes(now.getMinutes() + 30)
        break
      case '1h':
        end.setHours(now.getHours() + 1)
        break
      case '6h':
        end.setHours(now.getHours() + 6)
        break
      case '12h':
        end.setHours(now.getHours() + 12)
        break
      case '1d':
        end.setDate(now.getDate() + 1)
        break
      case '7d':
        end.setDate(now.getDate() + 7)
        break
      case '30d':
        end.setDate(now.getDate() + 30)
        break
      default:
        return
    }

    return { start: now, end }
  }

  const handleCheckboxChange = (value) => {
    setSelectedDuration(value)

    if (value !== 'Custom') {
      const dates = calculateEndDate(value)
      setStartDate(dates.start)
      setEndDate(dates.end)
    }
  }

  const createNewDrawing = () => {
    const error = []
    if (rewardDescription.trim() === '') {
      error.push('Please enter a reward description.')
    }
    if (!selectedDuration) {
      error.push('Please select a duration.')
    }
    if (error.length > 0) {
      toast.error(error.join('\n'), { style: { whiteSpace: 'pre-line' } })
      return
    }

    const endDateObj = endDate instanceof Date ? endDate : new Date(endDate)
    const roleId =
      selectedRole?.value ||
      roles.find((role) => role.name === '@everyone')?.id ||
      ''
    const infoDrawing = {
      guildId,
      userId,
      channelId: selectedChannel?.value || '',
      roleId,
      rewardDescription,
      numberOfWinners,
      startDate: startDate.toISOString(),
      endDate: endDateObj.toISOString(),
    }

    HttpService.postCreateGiveaway(infoDrawing)
      .then((response) => {
        setSelectedChannel(null)
        setSelectedRole(null)
        setRewardDescription('')
        setNumberOfWinners(1)
        setStartDate(new Date())
        setEndDate(new Date())
        toast.success('Giveaway created successfully!')
      })
      .catch((error) => {
        toast.error('Opss, something gone wrong.')
      })
  }

  return (
    <>
      <div className="container mx-auto flex flex-col gap-5">
        <div className="flex gap-4">
          <SelectDefault
            onChange={(e) => setSelectedChannel(e)}
            options={dropdownChannels}
            placeholder="Select a channel"
            label="Select the channel to participate in the giveway *"
          />
          <SelectDefault
            onChange={(e) => setSelectedRole(e)}
            options={dropdownRoles}
            placeholder="Select roles"
            label="Select a role that will participate in the giveway"
          />
        </div>
        <div>
          <span className="text-base font-semibold">Reward Description: *</span>
          <textarea
            value={rewardDescription}
            onChange={(e) => setRewardDescription(e.target.value)}
            className="w-full h-32 text-white outline-none bg-second rounded-lg p-2 border border-third overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-darkshadow-inner placeholder:text-white/35 placeholder:px-5"
            placeholder="Enter a description of the reward"
          />
        </div>
        <div className="flex items-start justify-between">
          {/* Number of Winners */}
          <div className="w-52 h-[53px] ">
            <InputNumber
              label="Set number of winners *"
              value={numberOfWinners}
              onChange={handleNumberOfWinners}
              minimum={1}
              setMinimum={1}
              maxSize={false}
            />
          </div>
          {/* Duration Selector */}
          <div className="flex flex-col">
            <span className="text-base font-semibold">Reward Description: *</span>
            <div className="flex flex-wrap w-[600px] gap-2">

              {optionsDuration.map((option, index) => (
                <div key={index} className="w-32 flex">
                  <Checkbox
                    label={option.label}
                    checked={selectedDuration === option.value}
                    onChange={() => handleCheckboxChange(option.value)}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Mostrar os DatePickers apenas quando Custom estiver selecionado */}
          {selectedDuration === 'Custom' && (
            <div className="flex items-end">
              <div className="flex flex-col gap-2 pr-2">
                <span>Start in:</span>
                <div className="flex h-full items-center justify-center w-[238px]">
                  <DatePicker
                    className="rmdp-prime bg-dark purple"
                    value={startDate}
                    onChange={setStartDate}
                    minDate={new Date()}
                    format="MM/DD/YYYY, HH:mm"
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      border: '1px solid #4e537b ',
                      height: '53px',
                      backgroundColor: '#2c2f48',
                      textAlign: 'center',
                    }}
                    containerStyle={{
                      width: '100%',
                      backgroundColor: '#2c2f48',
                    }}
                    plugins={[<TimePicker hideSeconds />]}
                  />
                  <RxDividerHorizontal size={32} className="text-white pl-2" />
                </div>
              </div>
              <div className="flex flex-col gap-2 w-52">
                <span>End in:</span>
                <DatePicker
                  className="rmdp-prime bg-dark purple"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={startDate}
                  format="MM/DD/YYYY, HH:mm"
                  style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    border: '1px solid #4e537b ',
                    height: '53px',
                    backgroundColor: '#2c2f48',
                    textAlign: 'center',
                  }}
                  containerStyle={{
                    width: '100%',
                    backgroundColor: '#2c2f48',
                  }}
                  plugins={[<TimePicker hideSeconds />]}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end pt-10">
          <Button
            color="deep-purple"
            variant="filled"
            className="bg-main"
            onClick={createNewDrawing}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  )
}

export default SetupGiveaway
