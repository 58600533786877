import { HttpService } from './axios-httpService'

export const getWallets = async (guildId) => {
  try {
    const response = await HttpService.getWalletsByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching wallets')
  }
}
