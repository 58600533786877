import { Button, Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchCollections } from '../../utils/collectionUtils'
import { InputDefault } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'
import AddTrait from './_components/AddTrait'

const CreateRevenueSharing = () => {
  const guildId = localStorage.getItem('guildId')
  const [isTraitTrue, setIsTraitTrue] = useState(false)
  const [nameToken, setNameToken] = useState('')
  const [amountPerNft, setAmountPerNft] = useState('')
  const [minimumStakedDays, setMinimumStakedDays] = useState('')
  const [endsInDays, setEndsInDays] = useState('')
  const [collectionSelected, setCollectionSelected] = useState('')
  const [loading, setLoading] = useState(false)
  //load collections
  const [collections, setCollections] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)

  useEffect(() => {
    if (!collections || collections.length === 0) {
      fetchCollections(guildId).then((data) => {
        setCollections(data)
      })
    }
  }, [])

  const collectionOptions = collections?.flatMap(
    (collection) =>
      collection.CollectionNotifications?.filter(
        (notification) => notification.IsEnabled && !notification.IsToken
      ).map((notification) => ({
        value: [
          {
            CollectionAddress: notification.CollectionMint,
            VerifiedAddress: notification.VerifiedAddress,
          },
        ],
        label: notification.DisplayName,
      })) || []
  )

  const saveRevenueSharing = async () => {
    setLoading(true)
    const errors = []
    if (!collectionSelected) {
      errors.push('Please select a collection')
    }
    if (!amountPerNft.trim()) {
      errors.push('Amount per NFT is required')
    }
    if (!nameToken.trim()) {
      errors.push('Token name is required')
    }
    if (!minimumStakedDays.trim()) {
      errors.push('Minimum staked days is required')
    }
    if (!endsInDays.trim()) {
      errors.push('Ends in days is required')
    }
    if (errors.length > 0) {
      toast.error(errors.join(' '))
      setLoading(false)
      return
    }

    const infoRevenueSharing = {
      GuildId: localStorage.getItem('selectedServer'),
      GuildName: '', //TODO: get guild name
      TokenName: nameToken,
      CollectionVerifiedAddress: '', //TODO: get collection verified address
      CollectionId: '', //TODO: get collection id
      AmountPerNft: amountPerNft,
      MinimumStakedDays: minimumStakedDays,
      CreatedDate: new Date().toISOString(),
      ExpireDate: new Date(
        new Date().getTime() + endsInDays * 24 * 60 * 60 * 1000
      ).toISOString(),
      TraitRewards: isTraitTrue ? traitRewards : [],
    }

    try {
      const response =
        await HttpService.postCreateRevSharing(infoRevenueSharing)
      console.log(response)
      setLoading(false)
      toast.success('Revenue sharing saved successfully')
    } catch (error) {
      console.error('Failed to save revenue sharing', error)
      toast.error('Failed to save revenue sharing')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <section className=" text-white flex justify-center">
        <div className="flex flex-col gap-y-5 w-[1000px]">
          {/* Name of your token */}
          <div className="flex gap-x-5">
            {/* nameToken */}
            <InputDefault
              label="Enter the name of your token*"
              placeholder="Example: SOL."
              onChange={(e) => setNameToken(e.target.value)}
              value={nameToken}
            />
            {/* Amount per NFT */}
            <InputDefault
              label="Amount per NFT*"
              placeholder="Example: 0.05"
              onChange={(e) => setAmountPerNft(e.target.value)}
              value={amountPerNft}
            />
          </div>
          <div className="flex gap-x-5">
            {/* Collection */}
            <SelectDefault
              label="Collection address that will receive revenue*"
              options={collectionOptions}
              onChange={(value) => setSelectedCollection(value)}
            />
            {/* Minimum staked days */}
            <div className="flex w-full gap-x-5">
              <SelectDefault
                label="Minimum staked days*"
                onChange={(value) => setMinimumStakedDays(value)}
                options={[
                  { value: 0, label: '0' },
                  { value: 7, label: '7' },
                ]}
              />
              {/* Ends in days */}
              <InputDefault
                label="Ends is days*"
                placeholder="Example: 2"
                onChange={(e) => setEndsInDays(e.target.value)}
                value={endsInDays}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-y-10 ">
            <div className="flex w-full gap-x-5 justify-end">
              <div className="text-sm font-bold">
                {isTraitTrue ? 'Disable' : 'Enable'} trait rewards
              </div>
              <Switch
                color="deep-purple"
                className="ml-auto"
                onChange={(e) => setIsTraitTrue(e.target.checked)}
              />
            </div>
            {isTraitTrue && <AddTrait />}
          </div>

          {/* Button section */}
          <hr className="h-px my-5 bg-gray border-0" />
          <div className="flex justify-between">
            <Button
              className="bg-third text-white px-14 shadow-md shadow-shadow hover:bg-basic"
              onClick={() => {
                setNameToken('')
                setAmountPerNft('')
                setMinimumStakedDays('')
                setEndsInDays('')
                setIsTraitTrue(false)
                setTraitRewards([])
              }}
            >
              Cancel
            </Button>
            <Button
              className="bg-accentMain text-white px-10 shadow-md shadow-shadow hover:bg-accentHouver"
              onClick={saveRevenueSharing}
            >
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

export default CreateRevenueSharing
