import { Alert, Card } from '@material-tailwind/react'
import React, { useState, useEffect, Fragment } from 'react'
import { FaCrown } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import logo from '../../img/200x60.png'
import avatar from '../../img/AvatarTektools.png'
import { fetchGuilds } from '../../utils/guildUtils'
import DropdownChoose from '../ChooseServer/DropdownChoose'
import Tip from '../components/ui/tip'
import Notice from './Notice'
import dataSidebar from './dataSidebar'

const Sidebar = () => {
  const [openAlert, setOpenAlert] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const filteredData = dataSidebar.filter(
      (item) =>
        localStorage.getItem('guildId') === '1216871846067179620' || item.enable
    )
    setData(filteredData)
  }, [])

  const [guilds, setGuilds] = useState([])
  useEffect(() => {
    fetchGuilds().then((data) => {
      setGuilds(data)
    })
  }, [])

  const currentGuildId = localStorage.getItem('guildId')
  const currentGuild = guilds?.find((guild) => guild.id === currentGuildId)

  return (
    <>
      <Card
        color="transparent"
        className="m-0 h-full w-full shadow-2xl shadow-main text-white rounded-none border-r-2 border-main gap-3"
      >
        <div className="flex w-full justify-center py-5">
          <a href="/" className="flex gap-x-2">
            <img className="lg:h-12 h-8 w-auto" src={avatar} alt="Avatar" />
            <img className="lg:h-12 h-8 w-auto" src={logo} alt="Tektools" />
          </a>
          <span className="text-xs text-main font-black tracking-wider bg-second px-1 py-px rounded-full h-fit mt-1">
            BETA
          </span>
        </div>
        {/* <hr className="my-3 border-main shadow-main shadow" /> */}
        <div className="w-full px-2 z-30">
          <DropdownChoose />
        </div>
        <div className="flex flex-col gap-y-px pt-5 ">
          {data?.map((data, index) => (
            <Fragment key={index}>
              {!localStorage.getItem('guildId') === '1216871846067179620' ? (
                <NavLink
                  key={index}
                  to={
                    !data.premium || data.premium === false
                      ? data.link
                      : currentGuild &&
                          currentGuild?.paidAccount === true &&
                          data.premium === true
                        ? data.link
                        : '/dashboard/premium'
                  }
                  className={({ isActive }) =>
                    `w-full h-12 flex items-center gap-2 pl-4 font-medium transition-all duration-500 ${
                      isActive &&
                      currentGuild &&
                      currentGuild?.paidAccount === true
                        ? 'bg-gradient-to-l from-main hover:from-main/90 to-transparent text-white pl-6 shadow-lg shadow-main/20'
                        : 'hover:pl-5 bg-none hover:bg-main/10'
                    }`
                  }
                >
                  <div className="w-full flex gap-x-2">
                    <div className="h-5 w-5">{data.icon}</div>
                    {data.name}
                  </div>
                  <div>
                    {data.premium && (
                      <div className="bg-orange-50/10 rounded-full px-2 py-1">
                        <FaCrown
                          size={14}
                          className="text-xs text-orange-200"
                        />
                      </div>
                    )}
                  </div>
                  <div className="pr-2">
                    {data.tip && <Tip tip={data.tip} placement="right-end" />}
                  </div>
                </NavLink>
              ) : (
                <NavLink
                  key={index}
                  to={data.link}
                  className={({ isActive }) =>
                    `w-full h-12 flex items-center gap-2 pl-4 font-medium transition-all duration-500 ${isActive && 'bg-gradient-to-l from-main hover:from-main/90 to-transparent text-white pl-6 shadow-lg shadow-main/20'}`
                  }
                >
                  <div className="w-full flex gap-x-2">
                    <div className="h-5 w-5">{data.icon}</div>
                    {data.name}
                  </div>
                  <div>
                    {data.premium && (
                      <div className="bg-orange-50/10 rounded-full px-2 py-1">
                        <FaCrown
                          size={14}
                          className="text-xs text-orange-200"
                        />
                      </div>
                    )}
                  </div>
                  <div className="pr-2">
                    {data.tip && <Tip tip={data.tip} placement="right-end" />}
                  </div>
                </NavLink>
              )}
            </Fragment>
          ))}
        </div>
        <div className="mt-auto w-full hidden">
          <div className="p-3 w-full">
            <Alert
              open={openAlert}
              className="bg-dark p-5  w-full h-[350px] shadow-xl shadow-main/15"
              onClose={() => setOpenAlert(false)}
            >
              <Notice />
            </Alert>
          </div>
        </div>
      </Card>
    </>
  )
}

export default Sidebar
