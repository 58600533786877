import { HttpService } from './axios-httpService'

export const getToken = async (guildId) => {
  try {
    const response = await HttpService.getTokenByGuildId(guildId)
    return response.data
  } catch (error) {
    if (error.response.status === 500) {
      console.error('Error in API response', error.response.data)
      return error.response.data
    }
    throw new Error('Error in API response')
  }
}
