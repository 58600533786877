import Select from 'react-select'
import makeAnimated from 'react-select/animated'

export const SelectDefault = ({
  options,
  onChange,
  isDisabled,
  placeholder,
  label,
  ref,
  isMulti,
  isLoading,
  value,
}) => {
  const animatedComponents = makeAnimated()

  return (
    <>
      <div className="w-full flex flex-col justify-end shadow-lg ">
        <span className="text-base font-semibold">{label}</span>
        <div className="w-full flex flex-col bg-second rounded-lg  border border-third">
          <Select
            isClearable
            value={value}
            options={options}
            components={animatedComponents}
            onChange={onChange}
            isDisabled={isDisabled}
            isMulti={isMulti}
            isLoading={isLoading}
            placeholder={placeholder}
            className="basic-single-select"
            classNamePrefix="select overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic placeholder:text-white/35"
            styles={{
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#2C2F48',
                color: '#fff',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#B420FF #2C2F48',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                height: 'auto',
                maxHeight: '100%',
                color: '#fff',
                alignItems: 'center',
              }),
              container: (provided, state) => ({
                ...provided,
                color: '#fff',
                margin: 0,
                padding: 0,
                border: 0,
                width: '100%',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: '#fff',
              }),
              control: (provided, state) => ({
                ...provided,
                height: 'auto',
                maxHeight: '100%',
                maxWidth: '100%',
                backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                color: '#fff',
                borderRadius: 8,
                borderColor:
                  state.isFocused || state.isHovered ? '#B420FF' : '#2C2F48',
                boxShadow:
                  state.isFocused || state.isHovered
                    ? '0 0 0 0.5px #B420FF'
                    : 'none',
                paddingLeft: '1rem',
                transition: 'border-color 0.2s ease',
                '&:hover': {
                  borderColor: '#B420FF',
                  boxShadow: '0 0 0 0.5px #B420FF',
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? '#9945ff'
                  : state.isFocused
                    ? '#393D5E'
                    : '#2C2F48',
                color: state.isFocused ? '#B420FF' : '#fff',
                cursor: 'pointer',
                maxWidth: '97%',
                padding: '0.5rem 1rem',
                margin: '0.2rem 0',
                marginLeft: '0.5rem',
                marginRight: '0.5rem',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#393D5E',
                  paddingLeft: '1.5rem',
                  transition: 'all 0.3s ease',
                },
              }),
              multiValue: (provided) => ({
                ...provided,
                backgroundColor: '#9945ff',
                color: '#fff',
                borderRadius: 4,
                padding: '0 0.1rem',
                margin: '0 0.1rem',
                justifyContent: 'space-between',
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                color: '#fff',
              }),
            }}
            ref={ref}
          />
        </div>
      </div>
    </>
  )
}
