import { Card } from '@material-tailwind/react'
import React from 'react'
import Social from './Social'
import Wallets from './Wallets'

const SetupAccount = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))

  return (
    <section className="w-full h-full flex flex-col mx-auto container py-10 gap-5">
      <div className="flex flex-col w-full items-start gap-5">
        <div className="flex w-full relative pb-5 items-center justify-center">
          <div className="absolute top-0 left-0 w-[170px] h-full bg-main/30 rounded-t-full"></div>
          <img
            className="rounded-full h-20 lg:h-40 z-10 pl-2 pt-1"
            src={`https://cdn.discordapp.com/avatars/${userData.user.id}/${userData.user.avatar}`}
            alt="Avatar"
          />
          <div className="flex flex-col gap-y-1 w-full h-full justify-end ">
            <h2 className="text-4xl font-semibold ml-[6px] pl-2 flex items-center gap-x-1 bg-main/30 rounded-tr-full -mb-5 py-5 ">
              {userData.user.global_name}
            </h2>
          </div>
        </div>
        <Social />
      </div>
      <div className="w-full flex gap-x-5 h-full ">
        {/* lista de servidores com bot aqui */}
        <div className="w-full h-full flex flex-col gap-y-5">
          <Card className="bg-basic min-h-28  overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-lg h-full items-center justify-center">
            lista de servidores com bot aqui
          </Card>
        </div>
        {/* lista de wallets aqui */}
        <Wallets />
      </div>
    </section>
  )
}

export default SetupAccount
