import { Button, IconButton } from '@material-tailwind/react'
import EmojiPicker from 'emoji-picker-react'
import React, { useState, useEffect } from 'react'
import { MdOutlineEmojiEmotions } from 'react-icons/md'
import { toast } from 'react-toastify'
import { HttpService } from '../../utils/axios-httpService'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { SelectDefault } from '../components/ui/select'

const CreateTicket = () => {
  const guildId = localStorage.getItem('guildId')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState('')
  const [list, setList] = useState(['⁉️ Help/Question', '🤝 Collab'])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [customMessage, setCustomMessage] = useState(
    'If you need any help, have questions of any kind please select an option below that best categorizes your contact to open a ticket with us.'
  )

  const onEmojiClick = (emojiObject) => {
    setText((prevText) => prevText + emojiObject.emoji + ' ')
    setShowEmojiPicker(false)
  }

  const handleAddToList = () => {
    if (text.trim()) {
      setList((prevList) => [...prevList, text])
      setText('')
    }
  }

  const handleRemoveItem = (itemToRemove) => {
    setList((prevList) => prevList.filter((item) => item !== itemToRemove))
  }

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const saveTicketAssistantConfig = async () => {
    setLoading(true)
    const errors = []
    if (!selectedChannel) {
      errors.push('Select a channel')
    }
    if (!selectedRole) {
      errors.push('Select a role')
    }
    if (list.length === 0) {
      errors.push('Add at least one custom option')
    }
    if (customMessage.trim() === '') {
      errors.push('Enter a custom message')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), { style: { whiteSpace: 'pre-line' } })
    } else {
      const data = {
        guildId: localStorage.getItem('guildId'),
        roleId: selectedRole.value,
        channelId: selectedChannel.value,
        reasons: list,
        customMessage: customMessage,
      }
      try {
        const response = await HttpService.postCreateTicketAssistant(data)
        toast.success('Ticket assistant config saved successfully!')
        cancelTicketAssistantConfig()
      } catch (error) {
        toast.error('Opss, something gone wrong.')
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const cancelTicketAssistantConfig = () => {
    setSelectedChannel(null)
    setSelectedRole(null)
    setText('')
    setCustomMessage('')
    setList([])
  }

  return (
    <>
      <div className="container mx-auto flex flex-col gap-5">
        <div className="flex gap-4">
          <SelectDefault
            onChange={(e) => setSelectedChannel(e)}
            options={dropdownChannels}
            placeholder="Select a channel"
            label="Select the channel to open the ticket"
          />
          <SelectDefault
            onChange={(e) => setSelectedRole(e)}
            options={dropdownRoles}
            placeholder="Select roles"
            label="Select a role that helps with ticket support"
          />
        </div>
        <div className="flex flex-col gap-4">
          <div>
            <span className="text-base font-semibold">
              Ticket Support Message:
            </span>
            <textarea
              value={customMessage}
              onChange={(e) => setCustomMessage(e.target.value)}
              className="w-full h-32 text-white outline-none bg-second rounded-lg p-2 border border-third overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic shadow-darkshadow-inner"
              placeholder="Enter a custom message to send to the user when they open a ticket"
            />
          </div>
          <div className="w-full flex flex-col justify-end shadow-lg relative">
            <span className="text-base font-semibold">
              Enter custom options for tickets
            </span>
            <div className="w-full flex bg-second rounded-lg px-1 border border-third items-center h-[52px]">
              <div className="flex w-full items-center ">
                <input
                  placeholder="Enter custom options"
                  className="w-full  text-white  first-of-type:pl-4 outline-none bg-second border-none "
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddToList()
                      e.preventDefault()
                    }
                  }}
                />
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="p-2 relative"
                >
                  <MdOutlineEmojiEmotions size={20} />
                </button>
              </div>
              <div className="pl-5">
                <Button
                  variant="filled"
                  color="deep-purple"
                  className="bg-accentHouver"
                  onClick={handleAddToList}
                >
                  ADD
                </Button>
              </div>
            </div>

            {showEmojiPicker && (
              <div className="absolute top-full mt-2 right-0">
                <EmojiPicker
                  theme="dark"
                  reactionsDefaultOpen
                  searchDisabled
                  skinTonesDisabled
                  recentEmojis={false}
                  onEmojiClick={onEmojiClick}
                />
              </div>
            )}
          </div>
          {list && list.length > 0 && (
            <div className="flex flex-wrap gap-1">
              {list.map((item, index) => (
                <div
                  key={index}
                  className="text-white bg-accentHouver rounded-lg pl-2 py-1 gap-1 flex flex-row h-8"
                >
                  <div className="flex items-center gap-x-1">
                    <span
                      className="pr-px"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item}
                    </span>
                    <IconButton
                      size="sm"
                      variant="text"
                      className="text-red-500 h-6 w-6"
                      onClick={() => handleRemoveItem(item)}
                    >
                      x
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-end pt-10">
          <Button
            variant="filled"
            color="deep-purple"
            className="bg-main"
            onClick={saveTicketAssistantConfig}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateTicket
