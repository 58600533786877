import React, { useEffect, useState } from 'react'
import { BiTrash } from 'react-icons/bi'

const DecentralizedGallery = () => {
  const guildId = localStorage.getItem('guildId')
  const [imageGallery, setImageGallery] = useState([])

  return (
    <>
      <hr className="w-full container mx-auto text-third " />
      <div className="flex flex-wrap justify-center gap-5 overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
        {imageGallery.length > 0 ? (
          imageGallery
            .filter((imageUrl) => !imageUrl.endsWith('.json'))
            .map((imageUrl, index) => (
              <div
                key={imageUrl}
                className="w-1/6 flex flex-wrap justify-center gap-5  rounded-md relative group"
              >
                <div className="w-full h-full">
                  <img
                    src={imageUrl}
                    alt={`Imagem ${index + 1}`}
                    className="rounded-md zoom-in-50 p-1 group-hover:p-0 w-full h-full transition-all duration-300"
                  />
                  <button className="absolute inset-0 items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-all duration-300 flex">
                    {imageUrl.split('/').pop()}
                  </button>
                  <button
                    onClick={() => handleDeleteImage(imageUrl)}
                    className="absolute top-2 right-2 bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-all duration-300 flex"
                  >
                    <BiTrash size={20} />
                  </button>
                </div>
              </div>
            ))
        ) : (
          <p>No images found</p>
        )}
      </div>
    </>
  )
}

export default DecentralizedGallery
