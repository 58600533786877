import { Button, Card, IconButton, Input } from '@material-tailwind/react'
import React, { useState } from 'react'
import { BiRightArrow } from 'react-icons/bi'
import { BiLeftArrow } from 'react-icons/bi'
import { BiPlus, BiSearch } from 'react-icons/bi'
import { CgTrash } from 'react-icons/cg'
import { TbCurrencySolana } from 'react-icons/tb'
import { toast } from 'react-toastify'
import Switch from '../../../admin/components/ui/switch'
const walletDemo = [
  {
    address: '2z89gFhV43CaAn1a2ZZNhDnkkfkzzXPsRcscmqyave7T',
    isMain: true,
  },
  {
    address: '3w56gFhV43CaAn1a2ZZNhDnkkfkzzXPsRcscmqyavp9A',
    isMain: false,
  },
  {
    address: '4e87gFhV43CaAn1a2ZZNhDnkkfkzzXPsRcscmqyavi0J',
    isMain: false,
  },
  {
    address: '5r09gFhV43CaAn1a2ZZNhDnkkfkzzXPsRcscmqyavh3B',
    isMain: false,
  },
]

const Wallets = () => {
  const [wallets, setWallets] = useState(walletDemo)
  const [searchQuery, setSearchQuery] = useState('')

  const shortAddress = (address) => {
    if (!address) return ''
    return `${address.substring(0, 7)}  . . . ${address.substring(address.length - 5)}`
  }

  const changeMainWallet = (index) => {
    const updatedWallet = wallets.map((wallet, i) => ({
      ...wallet,
      isMain: i === index,
    }))
    setWallets(updatedWallet)
  }

  const deleteWallet = (index) => {
    if (wallets[index].isMain) {
      toast.error('Cannot delete main wallet')
      return
    }
    const updatedWallet = wallets.filter((_, i) => i !== index)
    setWallets(updatedWallet)
  }

  return (
    <div className="flex flex-col w-full  shadow-main">
      <Card className="bg-basic h-full shadow-md shadow-main/10 ">
        <div className="flex justify-between items-end py-2 px-5 border-b border-second">
          <span className="text-lg font-semibold">Wallets</span>
          <Button className="flex gap-x-1 items-center bg-main text-xs font-semibold">
            Add New Wallet
            <BiPlus className="h-5 w-5 " />
          </Button>
        </div>
        <Input
          placeholder="Filter by address or wallet type"
          variant="static"
          color="deep-purple"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
          className="w-full bg-second border-none px-5 text-white"
          icon={<BiSearch className="text-xl text-main -ml-10" />}
        />
        <div className="h-full max-h-[350px] overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic">
          <table className="w-full text-left ">
            <thead className="text-xs bg-second uppercase">
              <tr>
                <th scope="col" className="text-start py-2 pl-5 ">
                  Address
                </th>
                <th scope="col" className="text-start py-2 pl-5">
                  Wallet
                </th>
                <th scope="col" className="text-start py-2 pl-5"></th>
              </tr>
            </thead>

            <tbody>
              {wallets
                .filter((item) =>
                  item.address.toLowerCase().includes(searchQuery)
                )
                .map((item, index) => (
                  <tr
                    className="bg-basic hover:bg-mainHover/30 border-second border-t border-b"
                    key={index}
                  >
                    <td className="px-5 py-2 border-r border-second flex items-center gap-x-2">
                      <TbCurrencySolana size={30} className="text-main" />
                      {shortAddress(item.address)}
                    </td>
                    <td className="px-5 py-2 border-r border-second w-36">
                      <Switch
                        labelLeft={item.isMain ? 'Main' : 'Secondary'}
                        isChecked={item.isMain}
                        handleCheckboxChange={() => changeMainWallet(index)}
                      />
                    </td>
                    <td className="w-10 h-8 hover:bg-accentHover">
                      <Button
                        color="deep-purple"
                        className="bg-main hover:bg-mainHover flex items-center justify-center text-white h-full w-10 p-px rounded-none rounded-l-lg"
                        onClick={() => deleteWallet(index)}
                      >
                        <CgTrash size={20} />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-center gap-x-5 pt-3">
          <IconButton color="deep-purple" className="bg-main">
            <BiLeftArrow />
          </IconButton>
          <span className="text-sm font-bold">1</span>
          <IconButton color="deep-purple" className="bg-main">
            <BiRightArrow />
          </IconButton>
        </div>
      </Card>
    </div>
  )
}

export default Wallets
