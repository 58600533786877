import { HttpService } from './axios-httpService'

export const fetchRoles = async (guildId) => {
  try {
    const response = await HttpService.getRolesByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching roles')
  }
}

//TODO: Remover após a implementação do backend
export const getRoles = async () => {
  try {
    const roles = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/roles/${localStorage.getItem('guildId')}`
    )
    const data = await roles.json()
    return data
  } catch (error) {
    console.error('Error fetching roles:', error)
    return null
  }
}
