import { HttpService } from './axios-httpService'

export const fetchChannels = async (guildId) => {
  try {
    const response = await HttpService.getChannelsByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error finding channels')
  }
}

export const getChannel = async (guildId) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/channels/${guildId}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    }
  )
  if (!response.ok) {
    throw new Error('Erro na resposta da API')
  }
  const data = await response.json()
  return data
}
