import { HttpService } from './axios-httpService'

export const getRevenueSharingByGuildId = async (guildId) => {
  try {
    const response = await HttpService.getRevSharingByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error fetching revenue sharing')
  }
}
