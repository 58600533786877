import React from 'react'
import { UserMenu } from '../../account/Account/User/UserMenu'
import Header from '../../components/Header/Header'
import alpha from '../../img/alpha2.svg'

const HeaderAdmin = () => {
  return (
    <div className="flex flow-row z-50 h-20 items-end justify-end mb-5 mx-auto container">
      <div className="w-full h-12 m-5 rounded-full p-px border border-main shadow-main shadow-md items-center justify-center  text-white hidden">
        {' '}
        text info
      </div>
      <div className=" w-auto h-14 rounded-full p-0 border-r border-b border-t border-main shadow-main shadow-sm relative ">
        <div className="bg-second/20 w-full h-full rounded-full items-center justify-center flex text-white">
          <UserMenu />
        </div>
      </div>
    </div>
  )
}
export default HeaderAdmin
