import React, { useState } from 'react'
import { SlArrowDown } from 'react-icons/sl'
import CombineRoles from './CombineRoles'
import ReactionRoles from './ReactionRoles'
import RoleSelection from './RoleSelection'

const RoleSystem = () => {
  const [showRoleSelection, setShowRoleSelection] = useState(false)
  const [showReactionRoles, setShowReactionRoles] = useState(false)
  const [showCombineRoles, setShowCombineRoles] = useState(false)

  return (
    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="container mx-auto flex justify-between items-center pb-10">
        <h1 className="text-4xl font-bold w-full">Role System</h1>
      </div>

      <div className="flex flex-col items-center justify-center container mx-auto rounded-lg p-5 gap-y-20">
        {/* Role Selection */}
        <div className="w-full flex flex-col gap-y-2">
          <div className="text-2xl font-bold flex items-center justify-between">
            <span
              className="hover:cursor-pointer w-full"
              onClick={() => setShowRoleSelection(!showRoleSelection)}
            >
              Role Selection
            </span>
            <button
              className=""
              onClick={() => setShowRoleSelection(!showRoleSelection)}
            >
              <SlArrowDown
                size={20}
                className={`transition-all duration-300 ${showRoleSelection ? 'rotate-180' : 'rotate-0'}`}
              />
            </button>
          </div>
          <hr className="w-full border-third" />
          {showRoleSelection && (
            <>
              <p className="text-base italic tracking-wide pb-10">
                Role selection allows your members to pick their own roles,
                helpful in multiple scenarios where you may want to for ping
                only members of a specific rol or gate specific channel access.
              </p>
              <RoleSelection />
            </>
          )}
        </div>

        {/* Reaction Roles */}
        <div className="w-full flex flex-col gap-y-2">
          <div className="text-2xl font-bold flex items-center justify-between ">
            <span
              className="hover:cursor-pointer w-full"
              onClick={() => setShowReactionRoles(!showReactionRoles)}
            >
              Reaction Roles
            </span>
            <button
              className=""
              onClick={() => setShowReactionRoles(!showReactionRoles)}
            >
              <SlArrowDown
                size={20}
                className={`transition-all duration-300 ${showReactionRoles ? 'rotate-180' : 'rotate-0'}`}
              />
            </button>
          </div>
          <hr className="w-full border-third" />
          {showReactionRoles && (
            <>
              <p className="text-base italic tracking-wide pb-10">
                Reaction roles allow your members to react to a message to get a
                role.
              </p>
              <ReactionRoles />
            </>
          )}
        </div>

        {/* Combine Roles */}
        <div className="w-full flex flex-col gap-y-2 pb-10">
          <div className="text-2xl font-bold flex items-center justify-between">
            <span
              className="hover:cursor-pointer w-full"
              onClick={() => setShowCombineRoles(!showCombineRoles)}
            >
              Combine Roles
            </span>
            <button
              className=""
              onClick={() => setShowCombineRoles(!showCombineRoles)}
            >
              <SlArrowDown
                size={20}
                className={`transition-all duration-300 ${showCombineRoles ? 'rotate-180' : 'rotate-0'}`}
              />
            </button>
          </div>
          <hr className="w-full border-third" />
          {showCombineRoles && (
            <>
              <p className="text-base italic tracking-wide pb-10">
                Combine roles allows you to combine multiple roles into one.
              </p>
              <CombineRoles />
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default RoleSystem
