import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { HttpService } from '../../../utils/axios-httpService'
import { getToken } from '../../../utils/tokenUtils'
import SectionTokenCreation from '../../TokenCreation/SectionTokenCreation'

const TokenOption = ({ onStakedTokensChange }) => {
  const guildId = localStorage.getItem('guildId')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [returnTokenInfo, setReturnTokenInfo] = useState(null)
  const [tokenOptions, setTokenOptions] = useState([])
  const [stakedTokens, setStakedTokens] = useState([])

  useEffect(() => {
    if (!tokenOptions || tokenOptions.length === 0) {
      getToken(guildId).then((data) => {
        setTokenOptions(data)
      })
    }
  }, [])

  const listTokens = tokenOptions?.map((token) => ({
    value: token.TokenAddress,
    label: token.TokenName,
    image: token.TokenImageUrl,
  }))

  const [tokenInfo, setTokenInfo] = useState(null)
  const handleTokenInfoChange = (info) => {
    setTokenInfo(info)
  }

  const SaveToken = async () => {
    if (!tokenInfo || Object.values(tokenInfo).some((value) => !value)) {
      toast.error('Please fill in all fields before saving the token.')
      return
    }
    try {
      const response = await HttpService.postCreateToken(tokenInfo)
      toast.success('Token created successfully!')
      setIsModalOpen(false)
    } catch (error) {
      toast.error('Error creating token!')
    }
  }

  const handleTokenChange = (e) => {
    setStakedTokens(e)
  }

  useEffect(() => {
    onStakedTokensChange(stakedTokens)
  }, [stakedTokens, onStakedTokensChange])

  const [modalToken, setModalToken] = useState(false)

  return (
    <>
      <div className="flex flex-col gap-y-2 w-full h-full relative">
        <div className="flex flex-col gap-y-5">
          {/* Token Address */}
          <div className="w-full">
            <div className="w-full flex flex-col justify-end">
              <span className="text-base font-semibold">Token Address</span>
              {modalToken && (
                <div className="flex w-screen h-screen top-0 right-0 bg-dark/40 border-third fixed z-50">
                  <div className="w-[900px] h-fit flex flex-col bg-basic rounded-lg border border-third top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 relative items-center justify-center">
                    <div className="w-full flex justify-end pb-5">
                      <Button
                        color="red"
                        variant="text"
                        onClick={() => {
                          setModalToken(false)
                          setTokenInfo(null)
                        }}
                      >
                        X
                      </Button>
                    </div>
                    <SectionTokenCreation
                      onTokenInfoChange={handleTokenInfoChange}
                    />
                    <div className="w-full flex justify-end p-5">
                      <Button
                        color="red"
                        variant="text"
                        onClick={() => {
                          setModalToken(false)
                          setTokenInfo(null)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="deep-purple"
                        variant="filled"
                        className="bg-main"
                        onClick={SaveToken}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full flex flex-col justify-end bg-second rounded-lg border border-third">
                <CreatableSelect
                  onSelectResetsInput={true}
                  closeMenuOnSelect={true}
                  clearValue={true}
                  isClearable={true}
                  options={[
                    ...listTokens,
                    {
                      value: 'new',
                      label: (
                        <div
                          className="flex items-center justify-center py-5 border-b border-t border-third -ml-5 bg-basic hover:bg-main hover:font-bold transition-all duration-300"
                          onClick={() => setModalToken(true)}
                        >
                          Create New Token
                        </div>
                      ),
                    },
                  ]}
                  className="basic-multi-select text-white"
                  classNamePrefix="select overflow-y-auto scrollbar-thin scrollbar-thumb-second scrollbar-track-basic text-white"
                  styles={{
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#2C2F48',
                      color: '#fff',
                      overflowY: 'auto',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#B420FF #2C2F48',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: state.hasValue ? '0.5rem 0' : '0.5rem',
                      height: 'auto',
                      maxHeight: '100%',
                      color: '#fff',
                      alignItems: 'center',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      color: '#fff',
                      margin: 0,
                      padding: 0,
                      border: 0,
                      width: '100%',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: '#fff',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      height: 'auto',
                      maxHeight: '100%',
                      maxWidth: '100%',
                      backgroundColor: state.isFocused ? '#393D5E' : '#2C2F48',
                      color: '#fff',
                      borderRadius: 8,
                      borderColor:
                        state.isFocused || state.isHovered
                          ? '#B420FF'
                          : '#2C2F48',
                      boxShadow:
                        state.isFocused || state.isHovered
                          ? '0 0 0 0.5px #B420FF'
                          : 'none',
                      paddingLeft: '1rem',
                      transition: 'border-color 0.2s ease',
                      '&:hover': {
                        borderColor: '#B420FF',
                        boxShadow: '0 0 0 0.5px #B420FF',
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? '#9945ff'
                        : state.isFocused
                          ? '#393D5E'
                          : '#2C2F48',
                      color: state.isFocused ? '#B420FF' : '#fff',
                      cursor: 'pointer',
                      maxWidth: '97%',
                      padding: '0.5rem 1rem',
                      margin: '0.2rem 0',
                      marginLeft: '0.5rem',
                      marginRight: '0.5rem',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#393D5E',
                        paddingLeft: '1.5rem',
                        transition: 'all 0.3s ease',
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: '#9945ff',
                      color: '#fff',
                      borderRadius: 4,
                      padding: '0.2rem 0.5rem',
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: '#fff',
                    }),
                  }}
                  onChange={handleTokenChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TokenOption
