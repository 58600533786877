import React from 'react'
import CreatePasswordLock from './CreatePasswordLock'

const PasswordLock = () => {
  return (
    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="container mx-auto flex justify-between items-center pb-10">
        <h1 className="text-4xl font-bold">Password Lock</h1>
      </div>
      <div className="flex flex-col items-center justify-center container mx-auto rounded-lg p-5 gap-y-5">
        <hr className="w-full border-third" />
        <CreatePasswordLock />
      </div>
    </section>
  )
}

export default PasswordLock
