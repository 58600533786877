import React, { useState } from 'react'
import Switch from '../components/ui/switch'
import SetupGiveaway from './SetupGiveaway'

const Giveaway = () => {
  const [enableGiveaway, setEnableGiveaway] = useState(false)
  return (
    <section className="text-white py-2 sm:py-5 relative h-full">
      <div className="container mx-auto flex justify-between items-center pb-10">
        <h1 className="text-4xl font-bold">Giveaway</h1>
      </div>
      <div className="flex flex-col items-center justify-center container mx-auto rounded-lg p-5 gap-y-5">
        <hr className="w-full border-third" />
        <SetupGiveaway />
      </div>
    </section>
  )
}

export default Giveaway
