import { HttpService } from './axios-httpService'

export const getStaking = async (guildId) => {
  try {
    const response = await HttpService.getStakingByGuildId(guildId)
    return response.data
  } catch (error) {
    throw new Error('Error in API response')
  }
}
