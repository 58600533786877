import { Button } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { fetchRoles } from '../../utils/rolesUtils'
import { InputDefault } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'

const CombineRoles = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [selectedOtherRole, setSelectedOtherRole] = useState(null)
  const [selectedRoleToReceive, setSelectedRoleToReceive] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter(
      (role) =>
        (selectedRole === null || role.id !== selectedRole.value) &&
        (selectedOtherRole === null || role.id !== selectedOtherRole.value) &&
        (selectedRoleToReceive === null ||
          role.id !== selectedRoleToReceive.value)
    )
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const combineRoles = () => {
    const errors = []
    if (!selectedRole) {
      errors.push('No role selected')
    }
    if (!selectedOtherRole) {
      errors.push('No role selected')
    }
    if (!selectedRoleToReceive) {
      errors.push('No role to receive selected')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), {
        style: { whiteSpace: 'pre-line' },
      })
    } else {
      toast.success(
        `Roles combined successfully: ${selectedRoleToReceive.label} is configured.`
      )
      setSelectedRole(null)
      setSelectedOtherRole(null)
      setSelectedRoleToReceive(null)
    }
  }
  return (
    <>
      <div className="w-full flex flex-col gap-5 pb-36">
        <div className="w-full flex gap-3">
          <SelectDefault
            placeholder="Select role for combine"
            label="Select role for combine"
            isLoading={isLoading}
            isDisabled={isLoading}
            options={dropdownRoles}
            value={selectedRole}
            onChange={(value) => setSelectedRole(value)}
          />
          <SelectDefault
            placeholder="Select role for combine"
            label="Select role for combine"
            isLoading={isLoading}
            isDisabled={isLoading}
            options={dropdownRoles}
            value={selectedOtherRole}
            onChange={(value) => setSelectedOtherRole(value)}
          />
          <SelectDefault
            placeholder="Select the role you will receive"
            label="Select a role"
            isLoading={isLoading}
            isDisabled={isLoading}
            options={dropdownRoles}
            value={selectedRoleToReceive}
            onChange={(value) => setSelectedRoleToReceive(value)}
          />
          <div className="flex gap-3 items-end">
            <Button
              variant="filled"
              color="purple"
              className="w-fit whitespace-nowrap h-12 bg-main"
              onClick={combineRoles}
            >
              Combine Roles
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CombineRoles
