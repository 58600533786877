import { Switch } from '@material-tailwind/react'
import React, { useState, useEffect } from 'react'
import { RolePerAttibute } from './RolePerAttibute'
import { RolePerQty } from './RolePerQty'

const RoleSetup = ({
  syncData,
  onRolesQuantity,
  onRolesAttribute,
  onSales,
  onListings,
}) => {
  const [isSales, setIsSales] = useState(false)
  const [isListings, setIsListings] = useState(false)
  const [rolePerQty, setRolePerQty] = useState([])
  const [rolePerAttribute, setRolePerAttribute] = useState([])
  const handleRolesPerQtyUpdate = (updatedRolesPerQty) => {
    setRolePerQty(updatedRolesPerQty)
  }

  const handleRolesPerAttributeUpdate = (updatedRolesPerAttribute) => {
    setRolePerAttribute(updatedRolesPerAttribute)
  }

  useEffect(() => {
    onRolesQuantity(rolePerQty), onRolesAttribute(rolePerAttribute)
  }, [rolePerQty, rolePerAttribute])

  return (
    <>
      <div className=" text-white flex flex-col gap-y-5 w-full  max-w-[1300px]">
        <span className="text-lg font-bold">
          Select the roles and their definitions
        </span>
        <div className="flex flex-col gap-y-5">
          {/* ------------------   Inputs de Roles    -----------------  */}
          <div className="flex flex-col gap-y-5">
            <RolePerQty listRoleQuantity={handleRolesPerQtyUpdate} />
            {syncData?.IsToken === false && (
              <>
                <hr className="h-px my-5 bg-gray border-0" />
                <RolePerAttibute
                  creatorAddress={syncData?.VerifiedAddress}
                  collectionMint={syncData?.CollectionMint}
                  listRoleAttribute={handleRolesPerAttributeUpdate}
                />
              </>
            )}
          </div>

          {/* ------------------   Switchs    -----------------  */}
          {syncData?.IsToken === false && (
            <div className="container mx-auto flex flex-col gap-y-2">
              <Switch
                className={`${isSales ? 'bg-mainHover' : ''}`}
                label="Sales"
                checked={isSales}
                onChange={() => {
                  const newSalesState = !isSales
                  setIsSales(newSalesState)
                  onSales(newSalesState)
                }}
              />
              <Switch
                className={`${isListings ? 'bg-mainHover' : ''}`}
                label="Listings"
                checked={isListings}
                onChange={() => {
                  const newListingsState = !isListings
                  setIsListings(newListingsState)
                  onListings(newListingsState)
                }}
              />
            </div>
          )}
          <hr className="h-px mt-5 mb-10 bg-gray border-0" />
        </div>
      </div>
    </>
  )
}

export default RoleSetup
