import { Button } from '@material-tailwind/react'
import EmojiPicker from 'emoji-picker-react'
import React, { useState, useEffect } from 'react'
import { BiTrash, BiX } from 'react-icons/bi'
import { MdOutlineEmojiEmotions } from 'react-icons/md'
import { toast } from 'react-toastify'
import { fetchChannels } from '../../utils/channelUtils'
import { fetchRoles } from '../../utils/rolesUtils'
import { InputButton } from '../components/ui/input'
import { SelectDefault } from '../components/ui/select'
import { rolesDataSimulated } from './dataSimulated'

const RoleSelection = () => {
  const guildId = localStorage.getItem('guildId')
  const [isLoading, setIsLoading] = useState(false)
  const [rolesDefineds, setRolesDefineds] = useState(
    rolesDataSimulated ? rolesDataSimulated : []
  )

  const handleDeleteRole = (id) => {
    setRolesDefineds(rolesDefineds.filter((role) => role.id !== id))
  }

  //Channels
  const [channels, setChannels] = useState([])
  const [selectedChannel, setSelectedChannel] = useState(null)

  useEffect(() => {
    if (!channels || channels.length === 0) {
      setIsLoading(true)
      fetchChannels(guildId).then((data) => {
        setChannels(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownChannels = channels
    ?.filter(
      (channel) =>
        selectedChannel === null || channel.id !== selectedChannel.value
    )
    ?.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }))

  //Roles
  const [roles, setRoles] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)

  useEffect(() => {
    if (!roles || roles.length === 0) {
      setIsLoading(true)
      fetchRoles(guildId).then((data) => {
        setRoles(data)
        setIsLoading(false)
      })
    }
  }, [])

  const dropdownRoles = roles
    ?.filter((role) => selectedRole === null || role.id !== selectedRole.value)
    ?.map((role) => ({
      value: role.id,
      label: role.name,
    }))

  const [textValue, setTextValue] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [emoji, setEmoji] = useState('')

  const handleTextChange = (e) => {
    const inputValue = e.target.value
    const emojiPattern = /[\p{Emoji}]/u
    const newTextValue = inputValue.replace(emojiPattern, '').trimStart()
    setTextValue(newTextValue)
  }

  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject.emoji)
    setShowEmojiPicker(false)
  }

  const newConfigRoleSelection = () => {
    const configRole = {
      guildId,
      roleId: selectedRole.value,
      displayRoleName: emoji ? `${emoji} ${textValue}` : textValue,
    }
    const errors = []
    if (!selectedRole) {
      errors.push('No role selected')
    }
    if (!textValue) {
      errors.push('Enter a custom name for the role')
    }
    if (errors.length > 0) {
      toast.error(errors.join('\n'), { style: { whiteSpace: 'pre-line' } })
    } else {
      console.log(configRole)
      const roleName = emoji ? `${emoji} ${textValue}` : textValue
      setRolesDefineds([
        ...rolesDefineds,
        { id: selectedRole.value, name: roleName, role: selectedRole.label },
      ])
      setTextValue('')
      setSelectedRole(null)
      setEmoji('')
    }
  }

  const [sendRoleSelection, setSendRoleSelection] = useState(false)

  const sendList = () => {
    if (!selectedChannel) {
      toast.error('No channel selected')
      return
    }
    console.log(rolesDefineds)
    toast.success('List sent successfully')
    setSendRoleSelection(false)
  }

  return (
    <>
      <div className="w-full flex flex-col gap-5">
        <div className="w-full flex gap-3">
          <div className="flex gap-3 w-full relative z-20">
            <SelectDefault
              options={dropdownRoles}
              onChange={(value) => setSelectedRole(value)}
              value={selectedRole}
              isLoading={isLoading}
              isDisabled={isLoading}
              placeholder="Select a role"
              label="Select role"
            />
            <InputButton
              onChange={handleTextChange}
              value={emoji ? `${emoji} ${textValue}` : textValue}
              placeholder="Ex: 🎮 Gamer"
              label="Enter display name you want the user to see"
            >
              <button
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="!absolute right-2 top-1/2 -translate-y-1/2 rounded text-white"
              >
                <MdOutlineEmojiEmotions size={20} />
              </button>
            </InputButton>
            {showEmojiPicker && (
              <div className="absolute top-full mt-2 right-0">
                <EmojiPicker
                  theme="dark"
                  searchDisabled
                  skinTonesDisabled
                  recentEmojis={false}
                  onEmojiClick={onEmojiClick}
                />
              </div>
            )}
          </div>
          <div className="flex gap-3 items-end">
            <Button
              variant="filled"
              color="purple"
              className="w-fit whitespace-nowrap h-12 bg-main"
              onClick={newConfigRoleSelection}
            >
              Add Role
            </Button>
          </div>
        </div>
        {rolesDefineds.length > 0 && (
          <div className="w-full h-auto flex">
            <div className="w-full flex flex-wrap gap-5 h-auto">
              {rolesDefineds.map((role) => (
                <div
                  key={role.id}
                  className="flex rounded-lg bg-second  shadow-xl"
                >
                  <div className="flex flex-col h-full rounded-l-lg w-full px-2 py-1">
                    <span className="flex text-base font-semibold">
                      {role.name}
                    </span>
                    <div className="w-full flex justify-end items-end   ">
                      <span className="flex items-end justify-end bg-second rounded-lg py-1 px-2 text-xs font-black text-main">
                        @{role.role}
                      </span>
                    </div>
                  </div>
                  <div className="flex h-full">
                    <Button
                      variant="text"
                      className="font-black flex items-center justify-center bg-main rounded-r-lg w-10 p-0"
                      onClick={() => handleDeleteRole(role.id)}
                    >
                      <BiTrash size={20} accentHeight={10} className=" " />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex items-end">
              <Button
                variant="filled"
                color="purple"
                className="w-fit whitespace-nowrap h-[53px] bg-main"
                onClick={() => setSendRoleSelection(true)}
              >
                Send list
              </Button>
            </div>
          </div>
        )}
      </div>
      {sendRoleSelection && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-dark/50 z-50">
          <div className="bg-second border border-third shadow-lg shadow-dark  text-white relative p-5 rounded-lg">
            <button
              className="absolute right-2 top-2"
              onClick={() => setSendRoleSelection(false)}
            >
              <BiX size={20} />
            </button>
            <div className="flex flex-col gap-5 pt-5">
              <span className="text-lg font-semibold">
                At any time you can submit the functions you have entered into
                the system.
              </span>
              <hr className="border-third" />
              <div>
                <SelectDefault
                  options={dropdownChannels}
                  onChange={(value) => setSelectedChannel(value)}
                  value={selectedChannel}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  placeholder="Select channel"
                  label="Select channel"
                />
              </div>
              <div className="flex items-end pt-10 justify-end">
                <Button
                  variant="filled"
                  color="purple"
                  className="w-fit whitespace-nowrap h-[53px]"
                  onClick={sendList}
                >
                  Send list
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RoleSelection
