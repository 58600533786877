import { Button } from '@material-tailwind/react'
import React, { useState } from 'react'
import { BiLeftArrowAlt } from 'react-icons/bi'
import CreateVotingSystem from './CreateVotingSystem'
import LoadVotingSystem from './LoadVotingSystem'

const VotingSystem = () => {
  const [openNewPoll, setOpenNewPoll] = useState(false)

  return (
    <>
      <section className="text-white py-2 sm:py-5 relative h-full">
        <div className="flex justify-between items-center container mx-auto pb-10">
          <h1 className="text-4xl font-bold">Voting System</h1>
          <Button
            color="purple"
            className="flex max-w-40 min-w-14 h-12 font-bold px-4 py-3 items-center justify-center "
            onClick={() => setOpenNewPoll((cur) => !cur)}
          >
            {!openNewPoll ? 'Create Poll' : <BiLeftArrowAlt size={20} />}
          </Button>
        </div>
        {openNewPoll ? <CreateVotingSystem /> : <LoadVotingSystem />}
      </section>
    </>
  )
}

export default VotingSystem
